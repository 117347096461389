import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-spa-js";
import axios from "axios";

const Home = () => {
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isLoading,
    error,
    isAuthenticated,
    user,
    logout,
  } = useAuth0();

  const [responseData, setResponseData] = useState("");
  const [fontColor, setFontColor] = useState("black");
  const [apiError, setApiError] = useState(null);

  const getRandomColor = () => {
    const colors = ["red", "blue", "green", "orange", "purple"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div className="container">
        <div className="alert alert-danger" role="alert">
          <strong>Error:</strong> {error.message}
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div className="container">
        <div className="alert alert-info" role="alert">
          Authentication is required
        </div>
      </div>
    );
  }

  const handleClick = async () => {
    try {
      const token = await getAccessTokenSilently({
        // const token = await getAccessTokenWithPopup({
        authorizationParams: {
          audience: "http://localhost:3001",
          scope: "offline_access read:hello",
        },
      });

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/hello`,
        {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setResponseData(response.data);
      setFontColor(getRandomColor());
      console.log("Response data", response.data);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setApiError(error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error:", error.message);
      }
      console.log(error.config);
    }
  };

  return (
    <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
      {isAuthenticated && (
        <>
          <h1 className="my-4">Hello, {user.name}!</h1>
          <div className="row justify-content-center my-4">
            <div className="col-lg-6 d-flex justify-content-center">
              <button
                className="btn btn-primary btn-lg me-2 text-nowrap flex-fill"
                onClick={handleClick}
              >
                <span className="btn-text">Call Lambda</span>
              </button>
              <button
                className="btn btn-secondary btn-lg text-nowrap w-100"
                onClick={logout}
              >
                <span className="btn-text">Log Out</span>
              </button>
            </div>
          </div>
          <div className="mt-4">
            <h2 style={{ color: fontColor }}>
              {responseData ? responseData : "\u00A0"}
            </h2>
            {apiError && (
              <div className="alert alert-danger" role="alert">
                <strong>Error:</strong> {apiError.message}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
