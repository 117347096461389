import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/Login";
import Home from "./components/Home";

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const App = () => {
  const auth0RedirectUri = `${window.location.origin}/home`;

  return (
    <Router>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        onRedirectCallback={() => {}}
        authorizationParams={{ redirect_uri: auth0RedirectUri }}
        // audience="http://localhost:3001"
        // scope="read:hello"
        // useRefreshTokens={true}
        cacheLocation="memory"
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          {/* Add a default fallback route */}
          <Route path="/*" element={<Login />} />
        </Routes>
      </Auth0Provider>
    </Router>
  );
};

export default App;
